import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`hello`}</h1>
    <p>{`this is my first blog post`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "512px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/eaf9e9f6159b50e474f0cac1d232e1e6/01e7c/favicon-512.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABEUlEQVQ4y+WUQU7DMBBF3RaWUARIbbKqEGXJkmNwiRlOMX/FBeYfgn0lbtAbVeIARWM7WdchOyw9eZxoXhKPMynNPRRMalxMBpW89iqckSTwKwWf1bivczvIuU8KrsK6E/BVwQcFOwW3jUTOvYJvCt+kan6c4XN3auyyUIydgEnKBreKliXX9wrfDm/YlZvefEqkVDvm2Mvufws94oWYpxZyTinoKIygj6cofDn+SpezykLzFwH7EG7EfP2XM1iFvYB3ceFDjUc1HhT8VpvEQcyPCr6H/UvMzwqeBPxpRQsnBc8K/4zWda3gWsAbBW/F2tAx9rWYr6LcmdoXx/WlDHl5L42pSGqDlIkMFY/izD5+Aewz6Ru/TA5AAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "favicon",
            "title": "favicon",
            "src": "/static/eaf9e9f6159b50e474f0cac1d232e1e6/01e7c/favicon-512.png",
            "srcSet": ["/static/eaf9e9f6159b50e474f0cac1d232e1e6/5a46d/favicon-512.png 300w", "/static/eaf9e9f6159b50e474f0cac1d232e1e6/01e7c/favicon-512.png 512w"],
            "sizes": "(max-width: 512px) 100vw, 512px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      